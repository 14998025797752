@import './theme.scss';

body {
  background-color: #667380 !important
}

.authContainer {
  width: 450px;
  min-height: 330px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}

.authHeader {
  text-align: center;
  border-bottom: 1px solid $primary;
}

.authTitle {
  color: #97A4B1;
  padding: 20px;
  line-height: 30px;
  font-size: 26px;
  font-weight: 600;
  margin: 0;
}

.authContent {
  padding: 30px 50px;
}

.form-control.underlinedInput {
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0;
  font-size: 1rem;
  line-height: 1.5;
  color: $tertiary;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-bottom: 1px solid $tertiary;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control.underlinedInput:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid $primary;
}