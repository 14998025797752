$primary:          #138383;
$primary-active:   #235F50;
$primary-soft:     #D8EDED;
$secondary:        #516B63;
$tertiary:         #757474;
$info:             #83BD24;
$success:          #198754;
$danger:           #DC3545;
$warning:          #FFC107;
$light:            #F8F9FA;
$dark:             #343A40;
$grey:             #EFEFEF;

$theme-colors: (
  "primary":          $primary,
  "primary-hover":    #41A78E,
  "primary-active":   $primary-active,
  "primary-soft":     $primary-soft,
  
  "secondary":        $secondary,
  "secondary-hover":  #7E9990, 
  "secondary-active": #284039,
  
  "tertiary":         $tertiary,
  
  "info":             $info,
  "success":          $success,
  "danger":           $danger,
  "warning":          $warning,
  "light":            $light,
  "dark":             $dark,
  "grey":             $grey,
 );

//Button padding and font size
$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-size: 1rem;
$input-btn-line-height: 1.5 !default;

$input-btn-padding-y-lg: 0.75rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: 1.25rem;

$input-btn-padding-x-sm: 0.5rem;
$input-btn-padding-y-sm: 0.375rem;
$input-btn-font-size-sm: 0.875rem;

$btn-border-radius: 0.25rem;


//Radio Buttons & Checkboxen
$form-check-input-width:  1.25em  !default;
$form-check-min-height:   1.25em  !default;

//Font sizes
$font-size-base: 1rem;
$h1-font-size:          $font-size-base * 2.5     !default;
$h2-font-size:          $font-size-base * 2       !default;
$h3-font-size:          $font-size-base * 1.75    !default;
$h4-font-size:          $font-size-base * 1.5     !default;
$h5-font-size:          $font-size-base * 1.25    !default;
$h6-font-size:          $font-size-base           !default;
$form-label-font-size:  $font-size-base           !default;

//Display headings
$display-font-sizes: (
  1: 4rem,
  2: 3.5rem,
  3: 3rem,
  4: 2.5rem
) !default;

//Font weights
$lead-font-weight:      400 !default;
$font-weight-semibold:  500 !default;
$font-weight-bold:      600 !default;
$display-font-weight:   500 !default;
$btn-font-weight:       500 !default;
$form-text-font-weight: 400 !default;
$table-th-font-weight:  600 !default;

//Font families
$font-family-sans-serif:'Asap'        !default;
$headings-font-family:  'Asap'        !default;
$btn-font-family :      'Asap'        !default;
$display-font-family:   'Roboto Slab' !default;





//Font styles
$display-font-style:    normal !default;
$headings-font-style:   normal !default;
$form-label-font-style: normal !default;


//Line Heights
$display-line-height: 1.3125 !default;


//Colors 
$headings-color:    #212529 !default;
$form-label-color:  #394858 !default;
$body-bg:           $light !default;
$link-color:        $primary;
$dropdown-link-color: $secondary;


//Border Radius
$border-radius: .25rem !default;

$card-spacer-x:                     0.75rem !default;
$card-border-width:                 0; //$border-width !default;
$card-border-radius:                0; 
$card-cap-bg:                       #EAEAEA;

$modal-content-border-width:        0;
$modal-content-border-radius:       0.5rem;

@import "~bootstrap/scss/bootstrap";

/*
Gap nicht als Variable gefunden
line-height für sm und lg Buttons nicht gefunden
Header h1-h6 font-sizes sind gleich geblieben
Pragraph Lead line height nicht gefunden
Headings line height erst mal default wert, da werte zwischen h1-h6 zu unterschiedlich und ich habe nur eine Variable
display-line-height soll individuell gesetzt werden können, jedoch gibt es noch keine Funktion die ein Array von line-heights verarbeiten würde.
Keine font family für "Form Input - Label" und "Form Input - Placeholder"
*/