@import './theme.scss';

$header-height: 4.5rem;
$header-content-margin: 2.25rem;
$footer-height: 20rem;

html,
body,
#root {
  height: 100%;
}

:target::before {
  content: "";
  display: block;
  height: $header-height;
  /* fixed header height*/
  margin: -$header-height 0 0;
  /* negative fixed header height */
}

.headerContainer {
  height: $header-height;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.25);
}

.contentContainer {
  background-color: #FFFFFF;
  min-height: 100%;
  margin-bottom: -$footer-height;
}

.contentContainer:before {
  content: "";
  display: block;
  height: $header-height;
}

.contentContainer:after {
  content: "";
  display: block;
  height: $footer-height;
}

.footerContainer {
  padding-top: 2.75rem;
  margin-top: 2rem;
  height: $footer-height;
  background-color: #083E3E;
  color: white;
}

.toc {
  position: sticky;
  top: $header-height + $header-content-margin;
  background-color: white;
}

#searchButton {
  border-color: #c6c7c8;
}

#searchButton:hover {
  cursor: unset;
}

#searchResult {
  margin-top: 1rem;
}

.toc .linkContainer,
#searchResult .linkContainer {
  border-bottom: 1px solid #C5C5C5;
  padding: 0.625rem 0 0.625rem 0.75rem;
  width: 100%;
}

.toc a,
#searchResult a {
  color: $secondary;
  text-decoration: none;
  font-size: 14px;
}

.valign-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center
}

.valign-wrapper .material-symbols {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.input-group-text .material-symbols {
  margin-right: 0;
}

.btn-light {
  color: $secondary;
}

.nav-link {
  color: $primary;
}

.articleNav {
  margin: 0 (-$grid-gutter-width*0.5) 3rem;
  padding: $navbar-padding-y ($grid-gutter-width*0.5);
}

.articleNav .nav-item.active {
  background-color: #D9D9D9;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  padding-top: 0.5rem;
}

.articleNav .nav-link.active {
  background-color: #D9D9D9;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
  padding-top: 1rem;
}

h2,
h3 {
  margin-bottom: 0;
}

.articleContent h3 {
  margin-top: 1rem;
}

.titleBar {
  margin-bottom: 2.5rem;
  height: $header-height - 0.25rem;
  display: flex;
  align-items: center;
  background: $primary;
}

.titleBar h2 {
  color: white;
}

.sectionHeader {
  margin-bottom: 2.5rem;
  padding: $card-cap-padding-y $card-cap-padding-x;
  // height: $header-height - 0.25rem;
  background: $primary-soft;
}

.sectionHeader h4 {
  margin-bottom: 0;
}

.sectionHeader span.material-symbols {
  color: $primary;
}

.articleTitle {
  margin-bottom: 2.5rem;
  color: $secondary;
}

.card {
  margin-bottom: 3rem;
}

.card-header h5 {
  color: black;
  margin-bottom: 0;
}

.cardContentLine {
  height: 2.5rem;
  align-content: center;
}

.cardContentLine:nth-child(even) {
  background-color: #F9F8F8;
}

.contentCard .material-symbols {
  color: $tertiary;
}

.sectionHeader,
.contentCardHeader.collapsable {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.contentCardHeader .material-symbols {
  color: $primary;
}

#contactCard .col,
#contactCard .col-md-1 {
  height: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.contentCard .hint {
  font-size: 0.875rem;
  font-weight: 500;
  color: $tertiary;
}

.carousel {
  margin-bottom: 3rem;
}

.carouselIcon {
  background-color: white;
  border-radius: 100%;
  color: $secondary;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.carouselIcon .material-symbols {
  margin: 0;
}

.listLine {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.listLineDot {
  font-size: 1.5rem;
}

.imageSlideshow img {
  object-fit: cover;
}

.imageSlideshow div.col-md-6 {
  margin-bottom: 1.5rem;
}

.noImageAvailable {
  background-color: #F6F6F6;
  height: 6.25rem;
}

.imageSlideshowModal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.imageSlideshowDialog {
  width: auto !important;
  display: table;
}

.searchForm {
  background-color: #D8EDED;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 1rem;
}

.searchForm input#searchTerm {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F8F9FA;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.searchForm select#state {
  display: block;
  width: 100%;
  padding: 0.5rem 2.25rem 0.5rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #F8F9FA;
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.searchResultHeader,
.searchMoreResults {
  margin-bottom: 1rem;
  color: $tertiary;
}

.scrollTopContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
}

.scrollTopButton {
  position: relative;
  right: 100%;
  top: 90%;
}

.truncateText {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
}

.specialistCard {
  margin-bottom: 3rem;
  border: 1px solid lightgrey;
  padding: 0.5rem 0;
  box-shadow: 0 0 0.5rem 0 grey;
}

.specialistCard .card {
  margin-bottom: 0;
  min-height: 100%;
}